import React from 'react';
import { ChevronLeft } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from '../../../constants/routes';
import { Namespace } from '../../../locales/translations';
import { AwarenessCampaignData } from '../../../models/AwarenessCampaign';
import { useAppSelector } from '../../../store/hooks';
import CampaignAddressList from '../CampaignAddressList';
import AwarenessCampaignMap from '../AwarenessCampaignMap';
import { useNavigate } from 'react-router-dom';
import { selectAllCampaignAddresses } from '../../../store/reducers/awareness_campaigns/addresses';
import CampaignAddressListHeader from './CampaignAddressListHeader';

interface AwarenessCampaignDetailContentProps {
    mission: AwarenessCampaignData;
}

const AwarenessCampaignDetailContent: React.FC<AwarenessCampaignDetailContentProps> = ({ mission }) => {
    const { t } = useTranslation([Namespace.ADDRESSES, Namespace.ACTIONS, Namespace.DATES]);
    const missionAddresses = useAppSelector((state) => selectAllCampaignAddresses(state));
    const navigate = useNavigate();

    return (
        <Box padding={2} pb={10} sx={{ height: '100vh', overflowY: 'auto' }}>
            <Box display="flex" alignItems="center" gap={2}>
                <IconButton
                    onClick={() => navigate(`/${AppRoutes.AWARENESS_CAMPAIGNS}`)}
                    sx={{ padding: 0 }}
                >
                    <ChevronLeft />
                </IconButton>
                <Typography variant="h4" gutterBottom sx={{ pb: 1.5 }}>
                    {mission.name}
                </Typography>
            </Box>
            <Typography variant="body2" color="text.secondary">
                {t('created_at', { ns: Namespace.DATES, date: mission.createdAt })}
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
                {t('addresses_visited', {
                    ns: Namespace.ADDRESSES,
                    count: missionAddresses.filter((a) => a.visited).length || 0,
                    total: missionAddresses.length || 0,
                })}
            </Typography>
            <Typography variant="body1" fontWeight="bold" gutterBottom>
                {t('deadline', { ns: Namespace.DATES, date: mission.deadline })}
            </Typography>
            {missionAddresses.length > 0 ? (
                <>
                    <Box sx={{ height: 500, width: '100%' }}>
                        <AwarenessCampaignMap
                            width={1000}
                            height={500}
                            points={missionAddresses}
                            showMapControls
                        />
                    </Box>
                    <CampaignAddressListHeader />
                    <CampaignAddressList />
                </>
            ) : (
                <Typography variant="body2" fontStyle="italic" color="text.secondary">
                    {t('no_address_added')}
                </Typography>
            )}
        </Box>
    );
};

export default AwarenessCampaignDetailContent;
