import { Navigate, useLocation } from "react-router-dom";
import { AppRoutes } from "../../constants/routes";
import { useAppSelector } from "../../store/hooks";


const AuthRoute = ({ children }: { children: JSX.Element }) => {
    const user = useAppSelector(state => state.user);
    const location = useLocation();
    const from = location.state?.from?.pathname || AppRoutes.AWARENESS_CAMPAIGNS;
    
    return user.isAuthenticated ? <Navigate to={from} /> : children;
};

export default AuthRoute;
