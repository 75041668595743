import { Box, Typography, Avatar, TextField, InputAdornment, Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../locales/translations';
import { useAppSelector, useAuth } from '../../store/hooks';
import LogoutRounded from '@mui/icons-material/LogoutRounded';
import Email from '@mui/icons-material/Email';
import LogoutDialog from './LogoutDialog';

const ProfilePage = () => {
    const { t } = useTranslation([Namespace.TITLES, Namespace.PROFILE, Namespace.ACTIONS]);
    const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
    const userEmail = useAppSelector((state) => (state.user.email));
    const username = useAppSelector((state) => (state.user.name));
    const { handleLogout } = useAuth();

    const onLogoutConfirm = async () => {
        await handleLogout();
        setOpenLogoutDialog(false);
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 2,
            height: '100vh',
            bgcolor: '#fff',
            position: 'relative'
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                maxWidth: '400px'
            }}>
                <Avatar
                    sx={{
                        width: 100,
                        height: 100,
                        bgcolor: 'teal',
                        my: 4
                    }}
                />

                <Typography variant="h3" sx={{ mb: 5 }}>
                    {username}
                </Typography>

                <TextField
                    fullWidth
                    variant="outlined"
                    value={userEmail}
                    disabled={true}
                    InputProps={{
                        readOnly: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <Email sx={{ color: '#aaa' }} />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        mb: 4,
                        '& .MuiOutlinedInput-root': {
                            borderRadius: 1
                        }
                    }}
                />

                <Box sx={{
                    position: 'absolute',
                    bottom: 80,
                    left: 0,
                    right: 0,
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <Button
                        color="error"
                        onClick={() => setOpenLogoutDialog(true)}
                        startIcon={<LogoutRounded />}
                        style={{
                            textTransform: 'none',
                            color: '#e74c3c'
                        }}
                    >
                        {t('logout', { ns: Namespace.PROFILE })}
                    </Button>
                </Box>
            </Box>

            <LogoutDialog
                open={openLogoutDialog}
                onClose={() => setOpenLogoutDialog(false)}
                onConfirm={onLogoutConfirm}
            />
        </Box>
    );
};

export default ProfilePage;