import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import AwarenessCampaignsMethods from '../../../actions/awarenessCampaigns';
import { Namespace } from '../../../locales/translations';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import SectionLoader from '../../_include/SectionLoader';
import AwarenessCampaignDetailContent from './AwarenessCampaignDetailContent';
import CampaignAddressesMethods from '../../../actions/campaignAddresses';

const AwarenessCampaignDetail: React.FC = () => {
    const dispatch = useAppDispatch();
    const { missionID } = useParams<{ missionID: string }>();
    const { t } = useTranslation([Namespace.ADDRESSES]);
    const userEmail = useAppSelector(state => state.user.email);
    const partnerID = useAppSelector((state) => state.user.partnerID);
    const loading = useAppSelector((state) => state.awareness_campaign.selected.loading);
    const mission = useAppSelector((state) => state.awareness_campaign.selected.selectedAwarenessCampaign);
    const missionsSorted = useAppSelector((state) => state.awareness_campaign.addresses.sorted);

    useEffect(() => {
        if (userEmail && partnerID && missionID && !missionsSorted) {
            dispatch(AwarenessCampaignsMethods.retrieve(partnerID, missionID));
            dispatch(CampaignAddressesMethods.getAddresses(userEmail, partnerID, missionID));
        }
    }, [userEmail, partnerID, dispatch, missionID, missionsSorted]);

    if (loading) return <SectionLoader />;

    return mission ? (
        <AwarenessCampaignDetailContent mission={mission} />
    ) : (
        <Box padding={2}>
            <Typography variant="body2" color="text.secondary">
                {t('error_loading_awareness_campaign', { ns: Namespace.SNACKS })}
            </Typography>
        </Box>
    );
};

export default AwarenessCampaignDetail;
