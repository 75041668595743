import { ControlPosition, Map } from '@vis.gl/react-google-maps';
import { useEffect, useState } from "react";
import { DEFAULT_ZOOM_LEVEL, GRENOBLE_COORDINATES, MapState, getBoundsZoomLevel, getCurrentUserLocation, getMapOptions } from "../../helpers/geo";
import { AddressWithErrors } from "../../models/AwarenessCampaign";
import AddressMarker from '../Map/AddressMarker';
import { getDangerLevel } from '../../actions/batch_action';
import { DANGER_COLORS } from '../../helpers/draw';
import UserLocationMarker from '../Map/UserLocationMarker';
import { CustomRecenterControl } from '../Map/CustomReCenterBtn';
import { useParams } from 'react-router-dom';

type AwarenessCampaignMapProps = {
    width: number;
    height: number;
    points: AddressWithErrors[];
    showMapControls: boolean;
}

export default function AwarenessCampaignMap({ width, height, points, showMapControls }: AwarenessCampaignMapProps) {
    const { missionID } = useParams<{ missionID: string }>();

    const [{ zoom, center }, setMapState] = useState<MapState>({
        zoom: 15,
        center: GRENOBLE_COORDINATES,
    });

    const defaultCenter = GRENOBLE_COORDINATES;

    useEffect(() => {
        if (points.length > 0) {
            let minLat = 999, minLng = 999, maxLat = -999, maxLng = -999;
            for (let { lat, lng } of points) {
                if (lat < minLat) minLat = lat;
                if (lat > maxLat) maxLat = lat;
                if (lng < minLng) minLng = lng;
                if (lng > maxLng) maxLng = lng;
            }

            const zoom = getBoundsZoomLevel({
                north: maxLat,
                east: maxLng,
                south: minLat,
                west: minLng,
            }, { width, height });

            const center = { lat: (minLat + maxLat) / 2, lng: (minLng + maxLng) / 2 };

            setMapState({
                zoom,
                center,
            });
        } else {
            setMapState({
                zoom,
                center: defaultCenter,
            });
        }
    }, [points, defaultCenter, width, height]);

    /**
     * Recenters the map to the user's current GPS location.
     */
    const userGpsRecenter = async () => {
        const location = await getCurrentUserLocation();
        if (location) {
            setMapState({
                zoom: DEFAULT_ZOOM_LEVEL,
                center: location,
            });
        }
    };


    return (
        <Map
            mapId={process.env.REACT_APP_MISSION_MAP_ID ?? 'mapid'}
            center={center}
            zoom={zoom}
            onCameraChanged={(e) => setMapState(e.detail)}
            {...getMapOptions(showMapControls)}
        >
            {(() => {
                let nonVisitedCounter = 0;
                return points.map((point) => {
                    const markerIndex = !point.visited ? nonVisitedCounter++ : undefined;
                    const zIndex = point?.visited ? 101 : 100;
                    return (
                        <AddressMarker
                            index={markerIndex}
                            key={point.ID}
                            point={point}
                            position={point}
                            addressKey={point.addressKey}
                            missionID={missionID}
                            color={DANGER_COLORS[getDangerLevel(point.errorsCount)]}
                            zIndex={zIndex}
                        />
                    );
                });
            })()}
            <CustomRecenterControl
                controlPosition={ControlPosition.INLINE_END_BLOCK_END}
                onRecenter={userGpsRecenter}
            />
            <UserLocationMarker />
        </Map>
    );
}
