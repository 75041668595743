import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import BatchesList from '../../AddressStats/BatchesList';
import { BackButton } from '../../common/Buttons';
import { AppRoutes } from '../../../constants/routes';
import BatchMethods from '../../../actions/batch_action';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import CampaignAddressesMethods from '../../../actions/campaignAddresses';
import AwarenessCampaignsMethods from '../../../actions/awarenessCampaigns';

const AwarenessCampaignBatchDetail: React.FC = () => {
    const navigate = useNavigate();
    const { missionID, addressKey } = useParams<{ missionID: string; addressKey: string }>();
    const dispatch = useAppDispatch();
    const [showStatistics, setShowStatistics] = useState(false);
    const partnerID = useAppSelector((state) => state.user.partnerID);
    const userEmail = useAppSelector(state => state.user.email);
    const missionsSorted = useAppSelector((state) => state.awareness_campaign.addresses.sorted);


    useEffect(() => {
        if (addressKey && partnerID) {
            dispatch(BatchMethods.fetchBatchDocs([addressKey], partnerID));
        }
        if (userEmail && partnerID && missionID && !missionsSorted) {
            dispatch(AwarenessCampaignsMethods.retrieve(partnerID, missionID));
            dispatch(CampaignAddressesMethods.getAddresses(userEmail, partnerID, missionID));
        }
    }, [userEmail, partnerID, dispatch, missionID, missionsSorted, addressKey]);

    const handleBackClick = useCallback(() => {
        if (showStatistics) {
            setShowStatistics(false);
        } else {
            navigate(`/${AppRoutes.AWARENESS_CAMPAIGNS}/${missionID}/${AppRoutes.DETAIL}`);
        }
    }, [showStatistics, navigate, missionID]);

    return (
        <Box padding={2} pb={10} sx={{ height: '100vh', overflowY: 'auto' }}>
            <BackButton onClick={handleBackClick} />
            <BatchesList showStats={showStatistics} onSeeMoreClick={() => setShowStatistics(true)} />
        </Box>
    );
};
export default AwarenessCampaignBatchDetail;