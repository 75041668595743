import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AwarenessCampaignData } from "../../../models/AwarenessCampaign";
import { LoadableContext, RootState } from "../../store";

const awarenessCampaignsAdapter = createEntityAdapter<AwarenessCampaignData>({
    selectId: awarenessCampaign => awarenessCampaign.ID,
    sortComparer: (awarenessCampaign1, awarenessCampaign2) => {
        return awarenessCampaign1.name.localeCompare(awarenessCampaign2.name)
    }
});


const initialState: LoadableContext = {
    loading: false,
    error: null,
};


export const awarenessCampaignsSlice = createSlice({
    name: 'awareness_campaigns_list',
    initialState: awarenessCampaignsAdapter.getInitialState(initialState),
    reducers: {
        startLoadingList: (state) => {
            state.loading = true;
            state.error = null;
        },
        setList: (state, { payload: awarenessCampaigns }: PayloadAction<AwarenessCampaignData[]>) => {
            state.loading = false;
            awarenessCampaignsAdapter.setAll(state, awarenessCampaigns);
        },
        addItem: (state, { payload: awarenessCampaign }: PayloadAction<AwarenessCampaignData>) => {
            state.loading = false;
            awarenessCampaignsAdapter.addOne(state, awarenessCampaign);
        },
        updateItem: (state, { payload: { ID, data } }: PayloadAction<{ ID: string, data: Partial<AwarenessCampaignData> }>) => {
            awarenessCampaignsAdapter.updateOne(state, {
                id: ID,
                changes: data,
            });
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const AwarenessCampaignsActions = awarenessCampaignsSlice.actions;

export const {
    selectAll: selectAllAwarenessCampaigns,
    selectById: selectAwarenessCampaignById,
    selectIds: selectAwarenessCampaignsIds
} = awarenessCampaignsAdapter.getSelectors((state: RootState) => state.awareness_campaign.list);

const AwarenessCampaignsReducer = awarenessCampaignsSlice.reducer;

export default AwarenessCampaignsReducer;