import { Navigate, useLocation } from "react-router-dom";
import { AppRoutes } from "../../constants/routes";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useEffect } from "react";
import SectionLoader from "../_include/SectionLoader";
import { fetchPartnerDoc } from "../../actions/partner_controller";
;

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
    const user = useAppSelector(state => state.user);
    const location = useLocation();

    const partnerID = useAppSelector(state => state.user.partnerID);
    const partnerLoaded = useAppSelector(state => Boolean(state.partner.partner));

    const dispatch = useAppDispatch();
    useEffect(() => {
        if (partnerID && !partnerLoaded) {
            dispatch(fetchPartnerDoc(partnerID));
        }
    }, [dispatch, partnerID, partnerLoaded]);

    if (!user.isAuthenticated) return <Navigate to={AppRoutes.LOGIN} state={{ from: location }} />;

    if (!partnerLoaded) return <SectionLoader />;

    return children;
};

export default PrivateRoute;
