import { Box, CssBaseline } from "@mui/material";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import AlgoliaMethods from "../actions/algolia";
import UserMethods from "../actions/user_action";
import { AppRoutes } from "../constants/routes";
import { useAppDispatch } from "../store/hooks";
import AddressSearch from "./AddressStats/AddressSearch";
import LoggedInLayout from "./Login/LoggedInLayout";
import LoginPage from "./Login/LoginPage";
import AwarenessCampaignDetail from "./AwarenessCampaigns/AwarenessCampaignDetail/AwarenessCampaignDetail";
import AwarenessCampaignsPage from "./AwarenessCampaigns/AwarenessCampaignsPage";
import AuthRoute from "./Routes/AuthRoute";
import PrivateRoute from "./Routes/PrivateRoute";
import ScanRfid from "./ScanRfid/ScanRfid";
import AwarenessCampaignBatchDetail from "./AwarenessCampaigns/AwarenessCampaignDetail/AwarenessCampaignBatchDetail";
import ProfilePage from "./Profile/ProfilePage";

function Pages() {
    const auth = getAuth();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, firebaseUser => {
            if (firebaseUser?.uid) {
                dispatch(UserMethods.setUser(firebaseUser));
                dispatch(AlgoliaMethods.getAlgoliaAPIKey);
            }
        });

        return () => unsubscribe();
    }, [auth, dispatch]);

    return (
        <Box display={"flex"} minHeight={"100vh"} flexDirection={"column"} bgcolor={theme => theme.palette.background.paper}>
            <CssBaseline />
            <Routes>
                <Route path={AppRoutes.LOGIN} element={<AuthRoute><LoginPage /></AuthRoute>} />
                <Route element={<PrivateRoute><LoggedInLayout /></PrivateRoute>}>
                    <Route path={AppRoutes.AWARENESS_CAMPAIGNS}>
                        <Route index element={<AwarenessCampaignsPage />} />
                        <Route path={`:missionID/${AppRoutes.DETAIL}`} element={<AwarenessCampaignDetail />} />
                        <Route path={`:missionID/${AppRoutes.BATCH_DETAIL}/:addressKey`} element={<AwarenessCampaignBatchDetail />} />
                    </Route>
                    <Route path={AppRoutes.SEARCH_MAP} element={<AddressSearch />} />
                    <Route path={AppRoutes.SCAN_RFID} element={<ScanRfid />} />
                    <Route path={AppRoutes.PROFILE} element={<ProfilePage />} />
                </Route>
            </Routes>
        </Box>
    );
}

export default Pages;
