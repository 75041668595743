import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import AwarenessCampaignsMethods from '../../actions/awarenessCampaigns';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectAllAwarenessCampaigns } from '../../store/reducers/awareness_campaigns/list';
import AwarenessCampaignCard from './AwarenessCampaignCard';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../locales/translations';

function AwarenessCampaignsPage() {
    const dispatch = useAppDispatch();
    const missions = useAppSelector(selectAllAwarenessCampaigns);
    const loading = useAppSelector(state => state.awareness_campaign.list.loading);
    const partnerID = useAppSelector((state) => state.user.partnerID);
    const email = useAppSelector((state) => state.user.email);
    const { t } = useTranslation([Namespace.ADDRESSES]);

    useEffect(() => {
        if (partnerID && email) {
            dispatch(AwarenessCampaignsMethods.getAwarenessCampaigns(partnerID, email));
        }
    }, [dispatch, partnerID, email]);

    return (
        <Box display="flex" flexDirection="column" height="100vh" sx={{ overflowY: 'auto' }}>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    {missions.length > 0 ? (
                        <Grid container spacing={3} padding={2} paddingBottom={8}>
                            {missions.map(mission => (
                                <Grid key={mission.ID} item xs={12} sm={6} md={3} lg={2}>
                                    <AwarenessCampaignCard missionData={mission} />
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                            <Typography variant="h6">{t('no_awareness_campaigns_added', { ns: Namespace.ADDRESSES, })}</Typography>
                        </Box>
                    )}
                </>
            )}
            <Outlet />
        </Box>
    );
}

export default AwarenessCampaignsPage;
