import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";


export enum StorageType {
    BATCHES = "batches",
    PARTNERS = "partners",
    AWARENESS_CAMPAIGNS = "missions",
    AWARENESS_CAMPAIGNS_ADDRESSES = "mission-addresses",
};

export function getStorageURL(storage: StorageType) {
    return `gs://${process.env.REACT_APP_PROJECT_ID}-${storage}`;
}

export async function getPictureURL(imageURL: string, storageType: StorageType) {
    return await getFileURL(`${getStorageURL(storageType)}/${imageURL}`);
}

export async function getFileURL(filepath: string) {
    const storage = getStorage();
    const gsReference = ref(storage, filepath);

    return getDownloadURL(gsReference)
        .then((url) => {
            return url;
        })
        .catch((error) => {
            console.error(error);
            return null;
        });
}

export async function uploadFile(file: File, filepath: string) {
    const storage = getStorage();
    const storageRef = ref(storage, filepath);

    const snapshot = await uploadBytes(storageRef, file);
    const imageURL = await getDownloadURL(snapshot.ref);
    return imageURL;
}
