import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { CodeError } from '../actions/actions';
import { AppRoutes } from '../constants/routes';
import { showError } from './reducers/snacks';
import { UserActions } from './reducers/users';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/** Get the sorting errors preferences for the current partner. */
export const useSortingRules = () => {
  const errorsClasses = useAppSelector(state => state.sortingRules.errorsClasses);
  const mergingMapping = useAppSelector(state => state.sortingRules.mergingMapping);
  const displayedErrors = useAppSelector(state => state.sortingRules.displayedErrors);

  // Memoize the object combining the individual values
  return useMemo(() => ({
    errorsClasses,
    mergingMapping,
    displayedErrors,
  }), [errorsClasses, mergingMapping, displayedErrors]);
};

/**
 * Get the device's orientation (landscape or portrait).
 */
export const useScreenOrientation = () => {
  const getOrientation = () => window.screen.orientation.type;

  const [orientation, setOrientation] =
    useState(getOrientation())

  const updateOrientation = (event: Event) => {
    setOrientation(getOrientation())
  }

  useEffect(() => {
    window.addEventListener(
      'orientationchange',
      updateOrientation
    )
    return () => {
      window.removeEventListener(
        'orientationchange',
        updateOrientation
      )
    }
  }, [])

  return orientation
}

// Use throughout your app for handling all the auth method for logout and can be expanded in the future
export const useAuth = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      dispatch(UserActions.clearUser());
      navigate(AppRoutes.LOGIN);
      return true;
    } catch (e) {
      const error = e as CodeError | Error;
      console.error(`Error logging out: ${error.toString()}`);
      dispatch(showError(`Error logging user out`));
      return false;
    }
  };

  return {
    handleLogout
  };
};