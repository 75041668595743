import { Dialog, DialogTitle, DialogActions, IconButton, Button } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../locales/translations';
import React from 'react';

interface LogoutDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const LogoutDialog: React.FC<LogoutDialogProps> = ({ open, onClose, onConfirm }) => {
  const { t } = useTranslation([Namespace.PROFILE, Namespace.ACTIONS]);
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="logout-dialog-title"
    >
      <DialogTitle id="logout-dialog-title">
        {t('logout_confirmation', { ns: Namespace.PROFILE })}
        <IconButton
          onClick={onClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogActions>
        <Button onClick={onConfirm} color="error">
          {t('confirm', { ns: Namespace.ACTIONS })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutDialog;