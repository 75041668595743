import React, { useState } from 'react';
import DialogWrapper from './DialogWrapper';
import TopicsWithCheckboxes from './TopicsWithCheckboxes';
import CommentSection from './CommentSection';
import ImagePicker from './ImagePicker';
import QuestionWithRadio from './QuestionWithRadio';
import SubmitButton from './SubmitButton';
import CommunicationMethods from './CommunicationMethods';
import { Namespace } from '../../../locales/translations';
import MoodSelector, { ResponsivenessLevel } from './MoodSelector';
import CampaignAddressesMethods from '../../../actions/campaignAddresses';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReportFormData } from '../../../models/Forum';
import { useParams } from 'react-router-dom';

const TOPICS = [
    'garbage_bags',
    'bulky_items',
    'glass',
    'textiles',
    'illegal_dumping',
    'days_of_collection',
    'sorting_guide',
    'dedicated_bag',
];

type AddressReportProps = {
    open: boolean;
    onClose: () => void;
    address: string;
    addressID: string;
};

const AddressReport: React.FC<AddressReportProps> = ({ open, onClose, address, addressID }) => {
    const [inhabitantsSpoken, setInhabitantsSpoken] = useState<boolean | null>(null);
    const [inhabitantsReceptive, setInhabitantsReceptive] = useState<ResponsivenessLevel | null>(null);
    const [communicationMethods, setCommunicationMethods] = useState<string[]>([]);
    const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
    const [otherComments, setOtherComments] = useState<string>('');
    const [additionalInfo, setAdditionalInfo] = useState<string>('');
    const [images, setImages] = useState<File[]>([]);
    const partnerID = useAppSelector((state) => (state.user.partnerID));
    const dispatch = useAppDispatch();
    const { t } = useTranslation([Namespace.REPORT, Namespace.ACTIONS]);
    const { missionID } = useParams<{ missionID: string }>();

    const handleSubmit = async () => {
        const formData: ReportFormData = {
            inhabitantsSpoken,
            communicationMethods,
            selectedTopics,
            inhabitantsReceptive,
            otherComments,
            additionalInfo,
            imageURLs: []
        };
        await dispatch(CampaignAddressesMethods.updateCampaignAddress(partnerID!, missionID!, addressID, formData, images));
        onClose();
    };

    return (
        <DialogWrapper open={open} onClose={onClose}>
            <Typography variant='h3'>
                {t('intervention.question', { ns: Namespace.REPORT, address: address })}
            </Typography>
            <QuestionWithRadio
                question="communication.inhabitants"
                value={inhabitantsSpoken}
                onChange={setInhabitantsSpoken}
            />
            <CommunicationMethods
                communicationMethods={communicationMethods}
                onToggle={(method, checked) => setCommunicationMethods((prev) =>
                    checked ? [...prev, method] : prev.filter((m) => m !== method)
                )}
            />
            <TopicsWithCheckboxes
                topics={TOPICS}
                selectedTopics={selectedTopics}
                onToggle={(topic, checked) => setSelectedTopics((prev) =>
                    checked ? [...prev, topic] : prev.filter((t) => t !== topic)
                )}
            />
            <CommentSection rows={1} nameSpace={[Namespace.REPORT, Namespace.FORMS]} placeholder='sign_up.partner_type.other' value={otherComments} wordLimit={100} onChange={setOtherComments} />
            <MoodSelector
                question="reception.question"
                value={inhabitantsReceptive}
                onChange={setInhabitantsReceptive}
            />
            <CommentSection rows={4} nameSpace={[Namespace.REPORT, Namespace.REPORT]} label="comments.other" placeholder='comments.additional_info' value={additionalInfo} wordLimit={400} onChange={setAdditionalInfo} />
            <ImagePicker images={images} setImages={setImages} />
            <SubmitButton onSubmit={handleSubmit} />
        </DialogWrapper>
    );
};

export default AddressReport;
