import { Batch } from "../models/Batches";
import { formatAddress } from "./geo";

/**
 * Make the first character of a string uppercase
 * @param {string} string The string to transform
 * @return {string} The string with the first character uppercased
 */
export function ucFirst(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Check if an email address has a valid format
 * @param {string} emailAddress The email address to check
 * @return {boolean} True if valid, false otherwise
 */
export function isValidEmail(emailAddress: string): boolean {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return pattern.test(emailAddress);
}

export function isValidURL(text: string, options?: { acceptFacebookScheme?: boolean }) {
    let URLRegExp = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,})/);
    if (options?.acceptFacebookScheme) { // accept "{{facebookScheme}}/123456" (e.g. to redirect to Ficha's page on Facebook mobile app)
        URLRegExp = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|{{facebookScheme}}(\/[a-zA-Z0-9]*)*)/);
    }

    return text.match(URLRegExp) !== null;
}

export function getDisplayableURL(url: string): string {
    // remove protocol
    url = url.replace('https://', '');
    url = url.replace('http://', '');
    url = url.replace('www.', '');

    // remove trailing slash '/'
    const lastChar = url.slice(-1);
    if (lastChar === "/") {
        url = url.slice(0, url.length - 1);
    }

    return url;
}

export const getBatchKey = (batch: Pick<Batch, "hereID" | "address">): string => {
    if (batch.hereID) {
        return batch.hereID;
    } else if (batch.address) {
        return formatAddress(batch.address);
    } else {
        throw new Error("Batch has neither hereID nor address");
    }
};

export function slugify(str: string) {
    return String(str)
        .normalize('NFKD') // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
}

/**
 * Check if a string is in a list of strings, ignore casing.
 */
export function caseInsensitiveIncludes(array: string[], str: string) {
    for (let item of array) {
        if (item.toLowerCase() === str.toLowerCase()) return true;
    }

    return false;
}