import React from 'react';
import { List } from '@mui/material';
import { useAppSelector } from '../../store/hooks';
import CampaignAddressItem from './CampaignAddressItem';
import {  selectCampaignAddressesIds } from '../../store/reducers/awareness_campaigns/addresses';

const CampaignAddressList: React.FC = () => {
    const addresses = useAppSelector(state => selectCampaignAddressesIds(state));

    return (
        <List>
          {addresses.map((addressID) => (
                <CampaignAddressItem key={addressID} ID={addressID}/>
            ))}
        </List>
    );
};

export default CampaignAddressList;
