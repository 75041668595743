import { User as FirebaseUser, getAuth, sendSignInLinkToEmail, signInWithEmailLink } from 'firebase/auth';
import i18next from 'i18next';
import { stringifyUrl } from 'query-string';
import { Locale, Namespace } from '../locales/translations';
import { FirebaseUserClaims, NewUserData } from '../models/User';
import { hideMessage, showMessage } from '../store/reducers/snacks';
import { UserActions } from '../store/reducers/users';
import { AppDispatch } from '../store/store';
import { CodeError } from './actions';

/** 
 * Sends a magic link to the provided email address for sign-in.
 * @param email - The email address of the user.
 */
const requestMagicLink = (email: string,) => async (dispatch: AppDispatch) => {
    dispatch(UserActions.startLoading());

    try {
        const authInstance = getAuth();

        // URL of redirection when clicking on the magic link from the user's mailbox
        const linkURL = stringifyUrl({
            url: window.location.href,
            query: {
                email: email,
            },
        });

        // send magic link to the user's email
        await sendSignInLinkToEmail(authInstance, email, {
            url: linkURL,
            handleCodeInApp: true,
        });

        // display success message to user
        dispatch(showMessage({
            variant: "success",
            message: i18next.t("request_magic_link.success", { ns: Namespace.SNACKS, email }),
        }));
        dispatch(UserActions.stopLoading());
    }
    catch (e) {
        const error = e as CodeError | Error;
        console.error("Failed signing in", error);
        dispatch(UserActions.setError(error.message));
        dispatch(showMessage({
            variant: "error",
            message: i18next.t("invalid_link", { ns: Namespace.SNACKS }),
            moreInfo: error.message,
        }));
    }
};

/** 
 * Sign in using a magic link sent to the user's email address.
 * @param email - The email address of the user.
 * @param link - The link to the current user dashboard
 */
const signInWithMagicLink = (email: string, link: string) => async (dispatch: AppDispatch) => {
    dispatch(UserActions.startLoading());

    try {
        const authInstance = getAuth();

        await signInWithEmailLink(authInstance, email, link);
    }
    catch (e) {
        const error = e as CodeError | Error;
        dispatch(UserActions.setError(error.message));
        dispatch(showMessage({
            variant: "error",
            message: i18next.t("invalid_signin_link", { ns: Namespace.SNACKS }),
            moreInfo: error.message,
        }));
    }
};


const setUser = (firebaseUser: FirebaseUser) => async (dispatch: AppDispatch) => {
    if (firebaseUser) {
        const idTokenResult = await firebaseUser.getIdTokenResult();
        const claims = idTokenResult.claims as FirebaseUserClaims;
        const userData: NewUserData = {
            locale: claims.locale?.toString() as Locale || Locale.FRENCH,
            partnerPermissions: claims.partnerPermissions,
            isFichaAdmin: claims.isFichaAdmin,
            email: firebaseUser.email,
            name: firebaseUser.displayName,
        };
        dispatch(UserActions.setUser(userData));
        dispatch(hideMessage()); // hide error alerts if user tried to re-login with an already used link
        // Check if user has partner permissions
        const hasPartnerPermissions = userData.partnerPermissions &&
            Object.keys(userData.partnerPermissions).length > 0;

        // If user is not admin and doesn't have partner permissions, show error
        if (!userData.isFichaAdmin && !hasPartnerPermissions) {
            dispatch(showMessage({
                variant: "error",
                message: i18next.t("no_partner_id_found", {
                    ns: Namespace.SNACKS,
                    email: userData.email
                }),
                moreInfo: "User account partner ID not found"
            }));
        }
    }
}


const UserMethods = {
    setUser,
    requestMagicLink,
    signInWithMagicLink,
};

export default UserMethods;