import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import { Z_INDEX_BOTTOM_NAV_BAR } from '../../constants/theme';
import { Namespace } from '../../locales/translations';
import { OutlinedFlagOutlined, People, QrCodeScanner } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { useRef } from 'react';

function BottomNavigationComponent() {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation([Namespace.TITLES]);

    // Use a ref to store the last matched route
    const lastMatchedRoute = useRef(AppRoutes.AWARENESS_CAMPAIGNS);

    // Function to map subroutes to their main routes
    const mapPathnameToValue = (pathname: string) => {
        if (pathname.startsWith(`/${AppRoutes.SCAN_RFID}`)) {
            lastMatchedRoute.current = AppRoutes.SCAN_RFID;
            return AppRoutes.SCAN_RFID;
        } else if (pathname.startsWith(`/${AppRoutes.SEARCH_MAP}`)) {
            lastMatchedRoute.current = AppRoutes.SEARCH_MAP;
            return AppRoutes.SEARCH_MAP;
        } else if (pathname.startsWith(`/${AppRoutes.AWARENESS_CAMPAIGNS}`)) {
            lastMatchedRoute.current = AppRoutes.AWARENESS_CAMPAIGNS;
            return AppRoutes.AWARENESS_CAMPAIGNS;
        } else if (pathname.startsWith(`/${AppRoutes.PROFILE}`)) {
            lastMatchedRoute.current = AppRoutes.PROFILE;
            return AppRoutes.PROFILE;    
        } else {
            // Return last matched route
            return lastMatchedRoute.current;
        }
    };

    // Map current location to the value for BottomNavigation
    const currentValue = mapPathnameToValue(location.pathname);

    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: Z_INDEX_BOTTOM_NAV_BAR }} elevation={3}>
            <BottomNavigation
                value={currentValue}
                onChange={(event, newValue) => {
                    navigate(newValue);
                }}
                showLabels
            >
                <BottomNavigationAction
                    label={t('scan_rfid', { ns: Namespace.TITLES })}
                    value={AppRoutes.SCAN_RFID}
                    icon={<QrCodeScanner />}
                />
                <BottomNavigationAction
                    label={t('search', { ns: Namespace.TITLES })}
                    value={AppRoutes.SEARCH_MAP}
                    icon={<SearchIcon />}
                />
                <BottomNavigationAction
                    label={t('awareness_campaigns', { ns: Namespace.TITLES })}
                    value={AppRoutes.AWARENESS_CAMPAIGNS}
                    icon={<OutlinedFlagOutlined />}
                />
                 <BottomNavigationAction
                    label={t('profile', { ns: Namespace.TITLES })}
                    value={AppRoutes.PROFILE}
                    icon={<People />}
                />
            </BottomNavigation>
        </Paper>
    );
}

export default BottomNavigationComponent;
