import { useParams } from "react-router";
import { useAppSelector } from "../../store/hooks";
import { selectAllCampaignAddresses } from "../../store/reducers/awareness_campaigns/addresses";
import { selectBatchIds } from "../../store/reducers/batches/batch_reducer";
import { selectFilteredBatchIds } from "../../store/reducers/batches/filtered_batches";
import { selectRfidBatchIds } from "../../store/reducers/batches/rfid_batch_reducer";
import BatchCard from "./BatchCard";
import RefusalCard from "./RefusedCard";

type BatchCardListProps = {
}

const BatchCardList: React.FC<BatchCardListProps> = () => {
    const showRfid = useAppSelector(state => state.batch.rfid.showRfidsBatches);
    const showFilterData = useAppSelector(state => state.batch.filter.showFilter);
    const filteredBatchIds = useAppSelector(selectFilteredBatchIds);
    const batchIds = useAppSelector(showRfid ? selectRfidBatchIds : selectBatchIds);
    const batchIdsToRender = showFilterData ? filteredBatchIds : batchIds;
    const { addressKey } = useParams<{ addressKey: string }>();
    const missionAddresses = useAppSelector(state => selectAllCampaignAddresses(state));
    const addressData = missionAddresses.find((address) => address.addressKey === addressKey!);
    const isExternal = addressData?.fromExternalSource;
    const refusalReason = addressData?.refusalReason;

    if (isExternal && refusalReason) {
        return (
            <>
                {Object.entries(refusalReason).map(([date, reasons], index) => (
                    <RefusalCard
                        key={date + index}
                        date={date}
                        reasons={reasons}
                        index={index}
                    />
                ))}
            </>
        );
    }

    // Otherwise render batch cards
    return (
        <>
            {batchIdsToRender.map((batchID, index) => (
                <BatchCard key={batchID} batchID={batchID} index={index} />
            ))}
        </>
    );
};

export default BatchCardList;
