import { Card, CardHeader } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../locales/translations';

type RefusalCardProps = {
    date: string;
    reasons: string[];
    index: number;
};

const RefusalCard: React.FC<RefusalCardProps> = ({ date, reasons, index }) => {
    const { t } = useTranslation([Namespace.COMMONS, Namespace.DATES]);

    /**
     * Title for the card in the format "DD MMMM YYYY"
     */
    const cardTitle = t("date_date", {
        date: moment(date, 'DD-MM-YYYY'),
        ns: Namespace.DATES
    });

    return (
        <Card elevation={2} key={date || index}>
            <CardHeader
                title={cardTitle}
                titleTypographyProps={{
                    variant: "h5"
                }}
                subheader={reasons.join(', ')}
                subheaderTypographyProps={{
                    variant: "body1"
                }}
            />
        </Card>
    );
};

export default RefusalCard;