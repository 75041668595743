import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AwarenessCampaignData } from "../../../models/AwarenessCampaign";
import { LoadableContext } from "../../store";

type SelectedAwarenessCampaignContext = LoadableContext & {
    selectedAwarenessCampaign: AwarenessCampaignData | null;
    loadingStatus: boolean; // track when updating the campaign's firestore document
};

const initialState: SelectedAwarenessCampaignContext = {
    loading: false, // track when get/retrieving the campaign's firestore document
    loadingStatus: false,
    error: null,
    selectedAwarenessCampaign: null,
};

export const selectedAwarenessCampaignSlice = createSlice({
    name: 'selected_awareness_campaign',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        startLoadingStatus: (state) => {
            state.loadingStatus = true;
            state.error = null;
        },
        setSelected: (state, { payload: mission }: PayloadAction<AwarenessCampaignData | null>) => {
            state.loading = false;
            state.selectedAwarenessCampaign = mission;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
        clearSelected: (state) => {
            state.selectedAwarenessCampaign = null;
        },
        stopLoading: (state) => {
            state.loading = false;
            state.error = null;
        },
        stopLoadingStatus: (state) => {
            state.loadingStatus = false;
            state.error = null;
        },
    },
});

export const SelectedAwarenessCampaignActions = selectedAwarenessCampaignSlice.actions;

const SelectedAwarenessCampaignReducer = selectedAwarenessCampaignSlice.reducer;

export default SelectedAwarenessCampaignReducer;
