import { DocumentData, DocumentSnapshot, QueryDocumentSnapshot, collection, doc, getDocs, getFirestore, setDoc, updateDoc } from "firebase/firestore";
import { DbCollection } from "../constants/db";
import { AddressWithErrors } from "../models/AwarenessCampaign";
import { AwarenessCampaignsActions } from "../store/reducers/awareness_campaigns/list";
import { AppDispatch } from "../store/store";
import { fetchAPI, handleAPIError } from "./actions";
import { getStorageURL, StorageType, uploadFile } from "../helpers/storage";
import { ReportFormData } from "../models/Forum";
import { showError, showSuccess } from "../store/reducers/snacks";
import { CampaignAddressesActions } from "../store/reducers/awareness_campaigns/addresses";
import i18next, { Namespace } from "../locales/translations";
import { Coordinates } from "../helpers/geo";
import urls from "../constants/urls";

/**
 * Serialize an Awareness Campaign's Address data from their database document
 */
function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData>): AddressWithErrors;
function fromDbDoc(dbDoc: DocumentSnapshot<DocumentData>): AddressWithErrors;
function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>) {
    const data = dbDoc.data() as AddressWithErrors;
    const missionAddressData: AddressWithErrors = {
        ...data,
        ID: dbDoc.id,
    };

    return missionAddressData;
}

/**
 * Retrieves all addresses for a specific mission.
 * @param ambassadorEmail - The email of the current ambassador user.
 * @param partnerID - The ID of the partner.
 * @param missionID - The ID of the mission.
 * @returns The list of address documents.
 */
const getAddresses = (ambassadorEmail: string, partnerID: string, missionID: string) => async (dispatch: AppDispatch) => {
    dispatch(CampaignAddressesActions.startLoading());

    try {
        const db = getFirestore();
        const addressesRef = collection(db, DbCollection.PARTNERS, partnerID, DbCollection.AWARENESS_CAMPAIGNS, missionID, DbCollection.SENSITIZATION_ADDRESSES);
        const addressesSnapshot = await getDocs(addressesRef);

        const missionAddresses: AddressWithErrors[] = addressesSnapshot.docs
            .map(fromDbDoc)
            .filter(address => address.assigned?.email.toLowerCase() === ambassadorEmail.toLowerCase());

        dispatch(CampaignAddressesActions.setAddresses(missionAddresses));
        return missionAddresses;
    } catch (e) {
        dispatch(handleAPIError(e, "fetching mission addresses", AwarenessCampaignsActions.setError));
        return null;
    }
};

export const optimizeAddressRoute = (
    partnerID: string,
    startingPoint: Coordinates | null,
    addresses: AddressWithErrors[]
) => async (dispatch: AppDispatch) => {
    dispatch(CampaignAddressesActions.startLoading());
    try {

        const nonVisitedAddresses = addresses.filter(address => !address.visited);
        const visitedAddresses = addresses.filter(address => address.visited);

        if (nonVisitedAddresses.length === 0) {
            throw new Error('No valid addresses available for route optimization');
        }

        let firstPoint;

        // Determine starting point
        let effectiveStartingPoint: Coordinates;
        if (startingPoint && startingPoint.latitude !== 0 && startingPoint.longitude !== 0) {
            // Use provided starting point if valid
            effectiveStartingPoint = startingPoint;
        } else {
            // Use first valid point as starting point
            firstPoint = nonVisitedAddresses[0];
            effectiveStartingPoint = {
                latitude: firstPoint.lat,
                longitude: firstPoint.lng
            };

            // Remove the first point from the list of points to optimize
            nonVisitedAddresses.shift();
        }

        // Prepare request body with strict type checking
        const points = nonVisitedAddresses.map((address) => ({
            id: address.ID,
            coordinates: {
                latitude: parseFloat(address.lat.toFixed(6)),
                longitude: parseFloat(address.lng.toFixed(6))
            },
        }));

        const response = await fetchAPI(
            `${urls.API}/partner/${partnerID}/awareness-campaign/sort-itinerary`,
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    startPoint: {
                        latitude: parseFloat(effectiveStartingPoint.latitude.toFixed(6)),
                        longitude: parseFloat(effectiveStartingPoint.longitude.toFixed(6))
                    },
                    points
                }),
            }
        );

        // Add validation for the response
        if (!response || !Array.isArray(response)) {
            console.error("Invalid response format:", response);
            throw new Error('Invalid response format from sort-itinerary');
        }

        // Reorder addresses based on optimized route
        const addressMap = new Map(nonVisitedAddresses.map((addr) => [addr.ID, addr]));

        // Map the response to addresses, accounting for the actual response structure
        const sortedAddresses = [];

        for (const item of response) {
            if (!item || typeof item !== 'object' || !('id' in item)) {
                console.error(`Invalid item in response: ${JSON.stringify(item)}`);
                throw new Error(`Invalid item format in response`);
            }

            const id = item.id;
            const address = addressMap.get(id);

            if (!address) {
                console.error(`Address lookup failed for ID: ${id}`);
                console.error(`Available IDs in map: ${Array.from(addressMap.keys()).join(', ')}`);
                throw new Error(`Address ${id} not found in original list`);
            }

            sortedAddresses.push(address);
        }

        // If firstPoint exists, add it to the beginning of the sorted addresses
        if (firstPoint) {
            sortedAddresses.unshift(firstPoint);
        }

        // Combine sorted non-visited addresses with visited addresses at the end
        const finalSortedAddresses = [...sortedAddresses, ...visitedAddresses];

        // Update Redux state
        dispatch(CampaignAddressesActions.setSortedAddresses(finalSortedAddresses));

        // Show success message
        dispatch(showSuccess(i18next.t('address_sorted_successfully', { ns: Namespace.SNACKS })))

        return sortedAddresses;
    } catch (e) {
        const error = e as Error;
        console.error("Route Optimization Error:", error.message);
        dispatch(showError(error.message));
        return null;
    }
};

/**
 * Action to update mission addresses in Firestore individually, including image uploads.
 * 
 * @param partnerID - The ID of the partner.
 * @param missionID - The ID of the mission.
 * @param updatedCampaignAddress - A partial object of AddressWithErrors to update.
 * @param images - An array of files representing the images to upload.
 */
const updateCampaignAddress = (
    partnerID: string,
    missionID: string,
    addressID: string,
    updatedCampaignAddress: ReportFormData,
    images: File[]
) => async (dispatch: AppDispatch) => {

    try {
        dispatch(CampaignAddressesActions.startLoading());
        const db = getFirestore();

        // Reference to the 'sensitization_addresses' subcollection
        const addressesCollectionRef = collection(
            db,
            DbCollection.PARTNERS,
            partnerID,
            DbCollection.AWARENESS_CAMPAIGNS,
            missionID,
            DbCollection.SENSITIZATION_ADDRESSES
        );

        // Reference to the specific address document
        const addressDocRef = doc(addressesCollectionRef, addressID);

        const addressImagesUrl = getStorageURL(StorageType.AWARENESS_CAMPAIGNS_ADDRESSES);

        // Handle image uploads
        const uploadedImageURLs = await Promise.all(
            images.map(async (image) => {
                const filepath = `${missionID}/${addressID}/${Date.now()}_${image.name}`;
                const storagePath = `${addressImagesUrl}/${filepath}`;
                await uploadFile(image, storagePath);
                return filepath;
            })
        );

        // Update the report with uploaded image URLs directly inside the FormData
        const updatedReport: ReportFormData = {
            ...updatedCampaignAddress, // All fields from FormData
            imageURLs: uploadedImageURLs, // Attach the uploaded image URLs here
        };

        // Update the address document with report data
        await updateDoc(addressDocRef, {
            visited: true,
            report: updatedReport
        });

        dispatch(CampaignAddressesActions.updateAddressVisitedStatus({ addressID, changes: { visited: true }, }));
        dispatch(showSuccess(i18next.t('address_report_updated', { ns: Namespace.SNACKS })));

    } catch (error) {
        let errorMessage = i18next.t('unknown_error', { ns: Namespace.SNACKS });

        dispatch(handleAPIError(error, errorMessage, AwarenessCampaignsActions.setError));
    } finally {
        dispatch(CampaignAddressesActions.stopLoading());
    }
};


const CampaignAddressesMethods = {
    getAddresses,
    updateCampaignAddress,
    optimizeAddressRoute
};

export default CampaignAddressesMethods;
