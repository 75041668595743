import { Timestamp } from "firebase/firestore";
import { AddressComponents } from './Address';
import { ReportFormData } from "./Forum";
import { AmbassadorData } from "./User";

export enum AwarenessCampaignStatus {
    PENDING = 'PENDING',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED'
}

export type AwarenessCampaignDbData = {
    ID: string;
    name: string;
    deadline: Timestamp | undefined;
    createdAt?: Timestamp;
    /** For each ambassador added to the mission, indicate the number of addresses assigned to them. */
    assigned: { [email: string]: number };
    mapImageURL?: string;
    status?: AwarenessCampaignStatus;
    addressesCount: number;
    addressesVisitedCount: number;
}

export type AwarenessCampaignData = Omit<AwarenessCampaignDbData, "deadline" | "createdAt"> & {
    deadline: string | undefined;
    createdAt?: string;
}

export type AddressWithErrors = {
    ID: string;
    address: AddressComponents;
    addressKey: string;
    errors: Record<string, number>;
    errorsCount: number;
    lat: number;
    lng: number;
    batchesCount?: number;
    assigned: AmbassadorData;
    visited: boolean;
    report?: ReportFormData;
    fromExternalSource: boolean;
    refusalReason?: { [date: string]: string[] } | null;
}
