import { DocumentData } from "firebase/firestore";

export enum DbCollection {
    AREAS = "areas",
    BATCHES = "batches",
    COLLECTIONS = "collections",
    COLLECTIONS_SCHEDULES = "collections_schedules",
    IMAGES_TO_ANNOTATE = "images_to_annotate",
    INVITATIONS = "invitations",
    PARTNERS = "partners",
    PLACES = "places",
    ROUTES = "routes",
    TRUCKS = "trucks",
    AWARENESS_CAMPAIGNS = "missions",
    SENSITIZATION_ADDRESSES = "sensitization_addresses",
};



export type ModelData = DocumentData & { ID: string };