import { DocumentData, DocumentSnapshot, QueryDocumentSnapshot, collection, doc, getDoc, getDocs, getFirestore, setDoc } from "firebase/firestore";
import _ from "lodash";
import moment from "moment";
import { DbCollection } from "../constants/db";
import { AwarenessCampaignDbData, AwarenessCampaignData } from "../models/AwarenessCampaign";
import { AwarenessCampaignsActions } from "../store/reducers/awareness_campaigns/list";
import { SelectedAwarenessCampaignActions } from "../store/reducers/awareness_campaigns/selected";
import { AppDispatch } from "../store/store";
import { handleAPIError } from "./actions";
import { caseInsensitiveIncludes } from "../helpers/strings";

/**
 * Serialize a AwarenessCampaign's data from their database document
 */
function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData>): AwarenessCampaignData;
function fromDbDoc(dbDoc: DocumentSnapshot<DocumentData>): AwarenessCampaignData | null;
function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>) {
    const data = dbDoc.data() as AwarenessCampaignDbData;

    const awarenessCampaignData: AwarenessCampaignData = {
        ...data,
        ID: dbDoc.id,
        createdAt: data.createdAt ? moment(data.createdAt.toDate()).format('YYYY-MM-DDTHH:mm:ss') : undefined,
        deadline: data.deadline ? moment(data.deadline.toDate()).format('YYYY-MM-DDTHH:mm:ss') : undefined,
        addressesCount: data.addressesCount,
        addressesVisitedCount: data.addressesVisitedCount,
    };

    return awarenessCampaignData;
}

/**
 * Retrieves a list of awareness campaigns for a specific partner.
 * @param {AppDispatch} dispatch - The dispatch function from the Redux store.
 * @param {string} partnerID - The ID of the partner.
 * @param {string} email - The email of the user.
 * @returns {Promise<AwarenessCampaignData[]>} The list of awareness campaigns.
 */
const getAwarenessCampaigns = (partnerID: string, email: string) => async (dispatch: AppDispatch) => {
    dispatch(AwarenessCampaignsActions.startLoadingList());

    try {
        const db = getFirestore();
        // Directly access the specific partner's batches collection
        const awarenessCampaignsRef = collection(db, DbCollection.PARTNERS, partnerID, DbCollection.AWARENESS_CAMPAIGNS);
        const awarenessCampaignDocs = await getDocs(awarenessCampaignsRef); // Fetch all documents

        // Filter awareness campaigns to only include those assigned to the given email
        const awarenessCampaigns: AwarenessCampaignData[] = awarenessCampaignDocs.docs
            .map(fromDbDoc) // Convert documents to awareness campaign data
            .filter((campaign): campaign is AwarenessCampaignData =>
                campaign !== null && caseInsensitiveIncludes(Object.keys(campaign.assigned), email) 
            ); // Filter based on email

        dispatch(AwarenessCampaignsActions.setList(awarenessCampaigns));
        return awarenessCampaigns;
    } catch (e) {
        const error = e as Error;
        console.error("error:", e);
        dispatch(AwarenessCampaignsActions.setError(error.message));
        return [];
    }
};

/**
 * Retrieves a single awareness campaign for a specific partner.
 * @param dispatch - The dispatch function from the Redux store.
 * @param partnerID - The ID of the partner.
 * @param campaignID - The ID of the awareness campaign to retrieve.
 */
const retrieve = (partnerID: string, campaignID: string) => async (dispatch: AppDispatch) => {
    dispatch(SelectedAwarenessCampaignActions.startLoading());

    try {
        const db = getFirestore();
        const campaignRef = doc(db, `${DbCollection.PARTNERS}/${partnerID}`, DbCollection.AWARENESS_CAMPAIGNS, campaignID);
        const campaignDoc = await getDoc(campaignRef);

        // Filter out null values
        const campaign = fromDbDoc(campaignDoc);

        dispatch(SelectedAwarenessCampaignActions.setSelected(campaign));
        return campaign;
    } catch (e) {
        dispatch(handleAPIError(e, "retrieving awareness campaign", AwarenessCampaignsActions.setError));
        return null;
    }
};


const updateAwarenessCampaign = (partnerID: string, campaignID: string, updatedAwarenessCampaign: Partial<AwarenessCampaignDbData>) => async (dispatch: AppDispatch) => {
    dispatch(SelectedAwarenessCampaignActions.startLoadingStatus());
    try {
        const db = getFirestore();
        const campaignRef = doc(db, DbCollection.PARTNERS, partnerID, DbCollection.AWARENESS_CAMPAIGNS, campaignID);

        // Update the awareness campaign document in Firestore with the new data
        const sanitizedData = _.pickBy(updatedAwarenessCampaign, value => value !== undefined)
        await setDoc(campaignRef, sanitizedData, { merge: true });
        dispatch(SelectedAwarenessCampaignActions.stopLoadingStatus());

    } catch (e) {
        dispatch(handleAPIError(e, "updating campaign", AwarenessCampaignsActions.setError));
        return null;
    }
    dispatch(SelectedAwarenessCampaignActions.stopLoadingStatus());
};


const select = (campaign: AwarenessCampaignData) => (dispatch: AppDispatch) => {
    dispatch(SelectedAwarenessCampaignActions.setSelected(campaign));
};

const AwarenessCampaignsMethods = {
    select,
    getAwarenessCampaigns,
    retrieve,
    updateAwarenessCampaign,
};

export default AwarenessCampaignsMethods;
